.review-comment-modal {
  height: 95dvh;
  .ant-modal-content {
    height: 100%;
    max-height: unset !important;
    padding: 20px 12px;
    .ant-modal-close {
      width: 32px;
      height: 32px;
      background-color: #f9f9f9;
      border-radius: 8px;
      border: 0.5px solid #ebebeb;
    }
    .ant-modal-header {
      margin-bottom: 16px;
      .ant-modal-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 30.24px;
        text-align: center;
      }
    }
    .ant-modal-body {
      height: 100% !important;
      .overall-content-container {
        box-shadow: 0px 0px 15px 0px #0000001a;
        .ant-card-body {
          padding: 16px;
          .overall-rating-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 8px;

            .overall-score {
              font-size: 18px;
              font-weight: 700;
              line-height: 22.68px;
              text-align: center;
            }

            .total-review {
              font-size: 15px;
              font-weight: 400;
              line-height: 18.9px;
              text-align: center;
              text-transform: lowercase;
            }

            .overall-star-rate {
              .ant-rate-star svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }

      .detail-content-container {
        padding: 16px 4px 16px 4px;
        .order-review-title {
          font-size: 18px;
          font-weight: 700;
          line-height: 22.68px;
          padding: 16px;
          border-bottom: 1px solid #dfdfdf;
        }

        .order-review-list {
          margin-top: 12px;
          .order-review-container {
            display: flex;
            flex-direction: row;
            padding: 12px 16px 12px 16px;
            border-bottom: 1px solid #dfdfdf;
            max-height: 180px;
            .customer-image {
              flex: 1;
              .ant-image {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                .ant-image-img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
            .customer-review {
              flex: 10;
              .customer-name {
                font-size: 18px;
                font-weight: 600;
                line-height: 22.68px;
                text-align: left;
              }
              .rating {
                .ant-rate-star svg {
                  width: 18px;
                  height: 18px;
                }
              }
              .time {
                font-size: 15px;
                font-weight: 500;
                line-height: 18.9px;
                text-align: left;
              }
              .comment {
                font-size: 15px;
                font-weight: 500;
                line-height: 18.9px;
                text-align: left;
                margin-top: 14px;
                color: #787878;
                overflow-wrap: anywhere;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .review-comment-drawer {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    .ant-drawer-wrapper-body {
      .ant-drawer-header {
        border: 0px;
        .ant-drawer-header-title {
          flex-direction: row-reverse;

          .ant-drawer-close {
            margin: 0;
            width: 32px;
            height: 32px;
            padding: 4px 8px 4px 8px;
            border: 0.5px solid #ebebeb;
            background-color: #f9f9f9;
            border-radius: 8px;
          }

          .ant-drawer-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 22.68px;
            text-align: center;
          }
        }
      }

      .ant-drawer-body {
        padding: 0px 12px 12px 12px;

        .overall-content-container {
          box-shadow: 0px 0px 10px 0px #cccccc40;
          .ant-card-body {
            padding: 12px 18px;
            .overall-rating-container {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 8px;
              flex: 3;

              .overall-score {
                font-size: 18px;
                font-weight: 700;
                line-height: 22.68px;
                text-align: center;
              }

              .total-review {
                font-size: 13px;
                font-weight: 400;
                line-height: 16.38px;
                text-align: center;
                text-transform: lowercase;
              }

              .overall-star-rate {
                .ant-rate-star svg {
                  width: 16px;
                  height: 16px;
                }
              }
            }

            .rating-percent-container {
              flex: 5;
              .rating-percent {
                .review-ratio {
                  .ant-radio-group {
                    .ant-radio-wrapper {
                      margin-inline-end: 0px;
                      .rating {
                        .star-icon svg {
                          width: 12px;
                          height: 12px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .detail-content-container {
          padding: 16px;
          .order-review-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 22.68px;
            padding: 16px 4px 16px 4px;
            border-bottom: 1px solid #dfdfdf;
          }

          .order-review-list {
            .order-review-container {
              padding: 12px 4px 12px 4px;
              border-bottom: 1px solid #dfdfdf;
              display: flex;
              flex-direction: row;
              .customer-image {
                flex: 1;
                .ant-image {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  overflow: hidden;
                  .ant-image-img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
              }
              .customer-review {
                flex: 5;
                .customer-name {
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 22.68px;
                  text-align: left;
                }
                .rating {
                  .ant-rate-star svg {
                    width: 18px;
                    height: 18px;
                  }
                }
                .time {
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 18.9px;
                  text-align: left;
                }
                .comment {
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 18.9px;
                  text-align: left;
                  margin-top: 14px;
                  color: #787878;
                  overflow-wrap: anywhere;
                }
              }
            }
          }
        }
      }
    }
  }
}
