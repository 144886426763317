$margin_top: 30px;
$padding_login: 0px;
$number_width: 60px;
$number_height: 60px;
$margin_otp_left: 17px;
$number_width_mobile: 52px;
$number_height_mobile: 52px;
$margin_otp_left_mobile: 9.89px;

input[type="password"]::-ms-reveal {
  display: none;
}

.login-page-container-theme2 {
  padding: 60px 32px;

  .login_page_theme2 {
    margin: auto;
    width: 30%;
    height: 555px;
    height: fit-content;
    padding: 60px 16px;
    border-radius: 20px;
    align-items: center;
    text-align: center;
    position: relative;
    background-color: #fff;

    .space-title {
      margin-bottom: 24px;
    }

    .loading-overlay {
      position: absolute;
      z-index: 1000;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.7);

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1000;
      }
    }

    .login_title {
      padding-left: $padding_login;
      padding-right: $padding_login;

      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      align-items: center;
      text-align: center;
    }

    .login_detail {
      .forgot-password-btn {
        text-align: right;
        height: 18px;
        size: 15px;
        line-height: 18px;
        letter-spacing: 0.15px;
        color: #000000;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }

      .login_phone_icon {
        position: relative;
        padding-left: 18px;
        padding-right: 5px;
        top: 47px;
        z-index: 2;
        float: left;
      }

      .login_country {
        position: relative;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        z-index: 2;

        .login_country_popup {
          width: 600px;
        }

        float: left;
        top: 44px;
      }

      .login-by-account {
        position: absolute;
        .login_phone_icon {
          top: 18px;
        }
        .login_country {
          top: 13px;
        }
      }

      .input-password-theme2 {
        padding-left: 60px;
        height: 60px;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: #9f9f9f;
        border-radius: 12px;
      }

      .lock_icon {
        position: absolute;
        z-index: 2;
        left: 6%;
        margin-top: 15px;
      }

      .login_phone {
        padding-inline-start: 100px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        float: left;
        border-radius: 12px;
        display: flex;
        align-items: center;
        height: 56px;

        .ant-input {
          margin-left: 15px;
        }
      }

      .validate-password-theme2 {
        margin-bottom: 40px;
        border-radius: 12px;
        .ant-form-item-row {
          .ant-form-item-control {
            .ant-form-item-explain {
              .ant-form-item-explain-error {
                text-align: left;
                color: #d30000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }

      .validate-phone-theme2 {
        border-radius: 12px;
        .ant-form-item-row {
          .ant-form-item-control {
            .ant-form-item-explain {
              .ant-form-item-explain-error {
                text-align: left;
                color: #d30000;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .wrong-phone-or-password {
      border-radius: 12px;
      border: 1px solid #d30000;
      background: #fff0f0;
      height: 60px;
      display: flex;
      align-items: center;
      margin-top: 35px;
      margin-bottom: 40px;
      .wrong-phone-or-password-text {
        color: #d30000;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        top: 12px;
        margin-left: 12px;
        .close_icon {
          position: absolute;
        }
        .wrong-phone-or-password-text-theme2 {
          margin-left: 30px;
        }
      }
    }

    .login_phone_error {
      padding-left: $padding_login;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      float: left;
      color: #ff0000;
    }

    .login_firebase_error {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      float: left;
      color: #ff0000;
    }

    .login_button_box {
      .login_button {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        width: 100%;
        opacity: 1;
        border-radius: 12px;
        color: white;
      }
    }

    .login_otp_phone {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
    }

    .login_otp_title1 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 44px;
    }

    .login_otp_title2 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      align-items: center;
      color: #282828;
      margin-top: 28px;
    }

    .login_otp_title3 {
      margin: auto;
      text-align: center;

      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-top: 12px;
    }

    .login_otp_resend_title {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      color: #000000;
      margin-top: 25px;
    }

    .login_otp_resend1 {
      display: none;
      cursor: pointer;
      margin: auto;
      text-align: center;
      color: rgba(94, 141, 236, 1);
    }

    .login_otp_resend2 {
      cursor: pointer;
      margin: auto;
      text-align: center;
      color: rgba(190, 190, 190, 1);
    }

    .login_otp_numbers {
      margin: auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-top: 20px;
      width: fit-content;

      .input-otp__field {
        caret-color: transparent;
        cursor: not-allowed;
        pointer-events: none;
        &:nth-child(1 of :invalid) {
          cursor: auto;
          pointer-events: all;
        }
      }

      .login_otp_number1,
      input {
        height: $number_height;
        width: $number_width;
        text-align: center;
        background: #fef7ed;
        border-radius: 12px;

        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        align-items: center;
        text-align: center;
      }

      .login_otp_number2 {
        height: $number_height;
        width: $number_width;
        margin-left: $margin_otp_left;

        .login_otp_n2 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }

      .login_otp_number3 {
        height: $number_height;
        width: $number_width;
        margin-left: $margin_otp_left;

        .login_otp_n3 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }

      .login_otp_number4 {
        height: $number_height;
        width: $number_width;
        margin-left: $margin_otp_left;

        .login_otp_n4 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }

      .login_otp_number5 {
        height: $number_height;
        width: $number_width;
        margin-left: $margin_otp_left;

        .login_otp_n5 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }

      .login_otp_number6 {
        height: $number_height;
        width: $number_width;
        margin-left: $margin_otp_left;

        .login_otp_n6 {
          text-align: center;
          text-align: center;
          background: #fef7ed;
          border: 1px solid #6d6d6d;
          border-radius: 12px;
          width: 100%;
          height: 100%;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          text-align: center;
        }
      }
    }

    .login_otp_error {
      color: red;

      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      align-items: center;
      text-align: center;
      margin-top: 25px;
    }

    .login_button_resend_box {
      margin: auto;
      .login_button_resend {
        margin-top: 10px;
        width: 100%;
        height: 60px;
        border-radius: 12px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        text-align: center;
        color: #fef7ed;
      }
    }

    .login_otp_footer {
      height: 209px;
    }

    .tab-login-account-theme2 {
      margin-top: 32px;
      .ant-tabs-nav {
        .ant-tabs-nav-operations {
          display: none;
        }
        .ant-tabs-nav-wrap {
          display: block;
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              flex-grow: 1;
              margin-right: 0px;
              width: 100%;
              text-align: center;
              justify-content: center;
              margin: 0;
              .ant-tabs-tab-btn {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1599px) {
  .login-page-container-theme2 {
    .login_page_theme2 {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1399px) {
  .login-page-container-theme2 {
    .login_page_theme2 {
      width: 50%;
    }
  }
}

@media screen and (max-width: 999px) {
  .login-page-container-theme2 {
    .login_page_theme2 {
      width: 70%;
    }
  }
}

@media screen and (max-width: 576px) {
  .login-page-container-theme2 {
    .login_page_theme2 {
      padding-left: 16px;
      padding-right: 16px;
      width: 100% !important;
      .login_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 17px;
      }

      .login_detail {
        .login_country,
        .login_phone {
          font-size: 14px !important;
        }

        .lock_icon {
          padding-left: 18px;
          left: 2%;
        }
      }

      .login_otp_title1 {
        margin-top: 60px;
      }

      .login_otp_numbers {
        .login_otp_number1 {
          height: $number_height_mobile;
          width: $number_width_mobile;
        }

        .login_otp_number2 {
          height: $number_height_mobile;
          width: $number_width_mobile;
          margin-left: $margin_otp_left_mobile;
        }

        .login_otp_number3 {
          height: $number_height_mobile;
          width: $number_width_mobile;
          margin-left: $margin_otp_left_mobile;
        }

        .login_otp_number4 {
          height: $number_height_mobile;
          width: $number_width_mobile;
          margin-left: $margin_otp_left_mobile;
        }

        .login_otp_number5 {
          height: $number_height_mobile;
          width: $number_width_mobile;
          margin-left: $margin_otp_left_mobile;
        }

        .login_otp_number6 {
          height: $number_height_mobile;
          width: $number_width_mobile;
          margin-left: $margin_otp_left_mobile;
        }
      }

      .login_button_box {
        .login_button {
          font-weight: 700 !important;
          font-size: 14px !important;
          line-height: 17px !important;
        }
      }

      .login_button_resend_box {
        width: 100%;
      }

      .login_otp_footer {
        height: 84px;
      }
      .tab-login-account-theme2 {
        .ant-tabs-nav {
          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              white-space: break-spaces;
              .ant-tabs-tab {
                .ant-tabs-tab-btn {
                  font-style: normal;
                  font-weight: 600 !important;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.forgot-password {
  padding: 160px 0;
  margin: auto;
  width: 560px;
  height: 555px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  background-color: #fff;

  &__title {
    font-size: 32px;
    font-weight: 700;
  }

  &__sub-title {
    font-size: 16px;
  }

  .form-forgot {
    &__input-phone {
      display: flex;
      width: 100%;
      border-radius: 12px;
      position: relative;

      .ant-form-item {
        margin-bottom: 0;
        width: 100%;

        .ant-form-item-control-input {
          padding: 13px 13px 13px 135px;
          background: #f8f8f8;
          border-radius: 12px;
        }
        span.form-forgot__input-phone {
          border: none;
          background: #f8f8f8;
          box-shadow: none;

          input {
            background: #f8f8f8;
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }

    &__img {
      position: absolute;
      z-index: 2;
      left: 21px;
      top: 18px;
    }

    &__select {
      position: absolute;
      z-index: 2;
      left: 53px;
      top: 14px;
      .demo-option-label-item span:last-child {
        display: none;
      }
    }

    .btn-send-otp {
      button {
        width: 100%;
        height: 100%;
        padding: 15px;
        border-radius: 12px;

        span {
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }
      }
      button:disabled {
        opacity: 50%;
      }
    }
  }
}

.ant-form-item-explain-error {
  text-align: left;
}

.reset-password {
  padding: 160px 0;
  margin: auto;
  width: 560px;
  height: 555px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  background-color: #fff;

  &__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }
  &__password,
  &__confirm-password {
    background: #f1f1f1;
    border-radius: 12px;
    padding: 13px 16px;
    .ant-input {
      background: #f1f1f1;
      margin-left: 7px;
    }

    .ant-form-item-explain-error {
      text-align: left;
    }

    .ant-input::placeholder {
      font-size: 16px;
    }
  }

  .btn-password {
    button {
      padding: 14px;
      border-radius: 12px;
      transition: 0.2s;
      width: 100%;
      height: 100%;
      span {
        font-size: 20px;
        font-weight: 700;
        color: #ffffff;
      }
    }
    button:disabled {
      opacity: 50%;
    }
  }
}

//Handle break UI for Phone < 392px
@media screen and (max-width: 392px) {
  .login-page-container-theme2 {
    padding: 24px calc(calc(100% - 366px) / 2) !important;
    .login_page_theme2 {
      max-width: 366px !important;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

@media screen and (max-width: 575px) {
  .reset-password,
  .forgot-password {
    width: 90%;
    padding: 30px 0;
  }

  .forgot-password {
    &__title {
      font-size: 24px;
    }
  }
}
