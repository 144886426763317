$LINE_HEIGHT_24: 24;
$FONT_WEIGHT_400: 400;
$WHITE_COLOR: "#FFF";
.post-content-theme2 {
  width: 784px;
  height: auto;
  margin-bottom: 60px;

  .post-content {
    width: 100%;
    flex-direction: column;
    background-color: #fff;
    border-radius: 20px;

    .thumbnail {
      width: 100%;

      .thumbnail-url {
        width: 100%;
        aspect-ratio: 780 / 500;
        border-radius: 20px 20px 0 0;
        background-color: #fff;
        object-fit: cover;
        object-position: center;
        cursor: pointer;
      }
      .thumbnail-img {
        width: 784px;
        max-height: 466px;
        border-radius: 15px;
        background-color: #fff;
      }
    }
    .title {
      color: #db4d29;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      text-transform: uppercase;
      margin-top: 16px;
      padding: 0px 24px;
      padding-left: 0px;

      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .label {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin: 8px 0px;
      .label-text {
        color: #db4d29;
        font-size: 20px;
        line-height: 28px;
        overflow: hidden;
        -webkit-box-orient: vertical;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        text-overflow: ellipsis;
        cursor: pointer;
        max-width: 760px;
      }
      .label-text-details {
        font-size: 28px;
        color: #282828;
        max-width: 760px;
        line-height: 36px;
      }
    }
    .date-and-author {
      display: flex;
      align-items: center;
      .date {
        display: flex;
        color: #959595;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .author {
        margin-left: 24px;
        .text-by {
          color: #959595;
        }
        .author-by {
          margin-left: 4px;
        }
      }

      .viewer {
        display: flex;
        align-items: center;
        margin-right: 16px;

        .icon-view {
          display: flex;
          margin-right: 8px;
        }

        .text-view {
          color: #282828;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
    .description {
      color: #282828;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-top: 16px;
      max-width: 785px;
      padding: 0px 24px;
      word-break: break-word;

      .blog-list-page-content {
        color: #282828;
        
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      .content-blog-theme2, .blog-list-page-content {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .blog-list-page-content {
        color: #282828;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }

    .description-blog-detail {
      padding: 0px !important;
      * {
        font-family: inherit !important;
      }
    }
    
    .post-content-detail-theme2 {
      .date-and-author {
        margin-bottom: 16px;
      }
    }
  }
}

.post-content-blog-list-theme2 {
  .post-content {
    .title, .label, .date-and-author, .description {
      padding: 0px 24px;

      .title-blog-category {
        font-size: 16px;
        line-height: 20px;
      }
    }
    .description {
      padding-bottom: 24px;
    }
  }
}
@media (max-width: 1336px) {
  .post-content-blog-list-theme2 {
    .post-content {

      .title,
      .label,
      .date-and-author,
      .description {
        padding: 0px 10px;
      }
      .description {
        padding-bottom: 16px;
      }

      .thumbnail .thumbnail-url {
        width: 90vw;
      }

      .label .label-text {
        max-width: 85vw;
      }
    }
  }
}
@media (max-width: 576px) {
  .post-content-theme2 {
    width: 90vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .post-content {
      margin-bottom: 24px;
      .thumbnail {
        margin: 0 auto;
        .thumbnail-url,
        .thumbnail-img {
          // width: 360px;
          width: 90vw;
          max-height: 240px;
        }
      }
      .label {
        .label-text-details {
          font-size: 24px;
          line-height: 32px;
        }
      }

      .date-and-author {
        .author {
          margin-left: 0px;
        }
      }
    }
  }
}