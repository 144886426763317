.container {
    .review-ratio {
        .ant-radio-group.ant-radio-group-outline {
            display: flex;
            flex-direction: column;

            .ant-radio-wrapper {
                width: fit-content;
                border-radius: 4px;

                .ant-radio {
                    display: none;
                }
            }

            .ant-radio-wrapper.ant-radio-wrapper-checked {
                background-color: #F0F6FF;
            }

            .rating {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 6px;
                padding: 8px 12px;

                .ant-progress .ant-progress-inner {
                    width: 105px;
                }

                .ant-progress .ant-progress-bg {
                    height: 2px !important;
                    background-color: #FF8C21;
                }

                .star-icon {
                    width: 18px;
                    height: 18px;

                    svg path {
                        fill: #FF8C21;
                    }
                }
            }  
        }
    }
}