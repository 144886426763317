.product-detail-theme-pho-viet-section {
  width: 100%;
  height: max-content;
  background: #ffffff;

  .mt-24 {
    margin-top: 24px;
  }

  #productDetailBody {
    width: 100% !important;
    padding: 40px;
    .product-detail-theme-pho-viet-section-group {
      display: flex;
      width: 100%;
      background-attachment: initial;
      background-repeat: no-repeat !important;
      background-size: 100% 100% !important;
      background-position: center !important;
      margin: auto;
      padding: 24px;
      border-radius: 20px;
      background-color: #ffffff !important;

      .product-detail-theme-pho-viet-section-left {
        width: 45%;
        height: max-content;
        position: relative;
        margin-right: 36px;

        .product-detail-image-swiper {
          width: 100%;
          height: 100%;
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          aspect-ratio: 1/1;
          background-color: white;

          .swiper-button-prev,
          .swiper-button-next {
            color: #51526c !important;
          }

          .swiper-wrapper {
            .swiper-slide {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: unset !important;
              .image-zoom-in {
                width: 50% !important;
                height: 50% !important;
                position: unset !important;
              }
            }
          }
        }

        .slider-product-detail {
          width: 100%;
          height: 570px;
          border: 1px solid #e6e6e6;
          border-radius: 20px;
          display: inline-flex !important;
          align-items: center;
          justify-content: center;
          .product-detail-slider-image {
            width: 100%;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            height: 100%;
            .image-product-detail {
              width: 386px;
              height: 379px;
            }
          }
          .slick-arrow {
            width: 18.75px;
            height: 37.5px;
            z-index: 9999;
          }
          .slick-next {
            right: 4%;
          }
          .slick-prev {
            left: 4%;
          }
        }

        .swiper-small-image {
          .swiper-wrapper {
            .swiper-slide {
              width: 130px !important;
              height: 130px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 20px;
              border: 1px solid #e6e6e6;
              background-color: white !important;

              .image-zoom-out {
                width: 113px;
                height: 113px;
                border-radius: 20px;
              }
            }

            .swiper-slide-thumb-active {
              border: 1px solid #db4d29;
            }
          }
        }

        .discount-product-detail {
          position: absolute;
          top: 6px;
          left: 6px;
          max-width: max-content;
          max-height: max-content;
          background: #ffa318;
          border-radius: 16px;
          color: #fff;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9;
          padding: 8px 16px;
        }

        .mySwiper {
          width: 100%;
        }

        .ant-image,
        .ant-image-error {
          width: 100%;
          height: 100%;
          aspect-ratio: 1/1;
          border-radius: 21.2px;
          background-color: white;

          .product-image {
            width: 100%;
            height: 100%;
            aspect-ratio: 1/1;
            border-radius: 21.2px;
            object-fit: cover;
          }
        }

        .main-image-box {
          position: relative;
        }

        .image-sub-group {
          display: none; // hidden by new design.
          margin-top: 24px;
          width: fit-content;
          .ant-image {
            padding: 0;
            .image-sub,
            .swiper-slide-active {
              width: 130px;
              height: 130px;
              border-radius: 20px;
              border: 1px solid #db4d29 !important;
              padding: 8px;
            }
          }
        }
      }

      .product-detail-theme-pho-viet-section-right {
        width: 55%;

        .product-detail-theme-pho-viet-section-right-content {
          height: 100%;
          .wrapper-btn-add-to-cart {
            margin-top: auto;
          }
          .product-name {
            width: 100%;
            font-size: 36px;
            font-weight: 700;
            margin: unset;
          }

          .group-star-rate {
            display: flex;
            align-items: baseline;
            .total-review {
              margin-left: 12px;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
            }
          }

          .description-product {
            font-size: 14px;
            margin-top: 25.5px;
            text-align: justify;
            color: #959595;
          }

          .product-price {
            margin-top: 24px;
            display: flex;
            align-items: center;
            .product-price-left {
              display: flex;
              flex-wrap: wrap;
              .product-price-discount {
                color: var(--colorcustomize-title-text-color, #db4d29);
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 40px; /* 125% */
              }
              .product-price-original {
                color: #959595;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px; /* 150% */
                text-decoration: line-through;
              }
            }
            .product-price-right {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              width: 100%;
              padding: 0 10px;
              .product-price-btn-increase,
              .product-price-btn-decrease {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
                align-items: center;
              }

              .product-price-btn-decrease {
                background: #ebebeb;
                color: #51526c;
              }
              .product-price-btn-increase {
                background: #db4d29;
                color: #fff;
              }

              .product-price-quantity {
                margin: 0 46.5px;
              }
            }
          }

          .topping-and-option {
            display: flex;
            background: #f9f9f9;
            border-radius: 16px;
            padding: 24px;
            margin-top: 24px;
            gap: 24px;
            flex-direction: column;
          }

          .product-description {
            color: #959595;
            font-size: 14px;
            margin-top: 20px;
            text-align: justify;

            .ant-typography {
              color: #282828;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;

              .ant-typography-expand {
                color: #db4d29;
                font-size: 16px;
                font-weight: 600;
              }
            }
          }

          .group-multiple-price {
            margin-top: 24px;
            width: 100%;
            .size-header {
              color: #000;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              margin-bottom: 12px;
            }
            .group-collapse {
              border: unset;
              border-radius: 8px;

              .ant-collapse-item {
                border: unset;
                .ant-collapse-header {
                  background: #f9f9f9;
                  font-size: 20px;
                  font-weight: 700;
                  border-radius: 8px;
                }
                .ant-collapse-content {
                  border: unset;
                  .ant-collapse-content-box {
                    padding: unset !important;

                    .group-multiple-price-content {
                      display: grid;
                      align-items: center;

                      .group-multiple-price-item {
                        height: fit-content;
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: unset;
                        border-bottom: 1px solid #e6e6e6;

                        .price-name {
                          height: 100%;
                          display: grid;
                          align-items: center;
                          padding: 8px 0;
                          margin-left: 16px;

                          .name {
                            font-size: 16px;
                            font-weight: 500;
                          }

                          .price-name-value {
                            font-size: 14px;
                            font-weight: 400;
                          }
                        }

                        .checkbox-price {
                          margin-right: 16px;
                        }

                        .price-name-value-radio {
                          width: 24px;
                          height: 24px;
                        }

                        .ant-radio-wrapper {
                          .ant-radio {
                            .ant-radio-inner {
                              border: 1px solid #a5abde;
                            }
                          }

                          .ant-radio-checked {
                            .ant-radio-inner {
                              border: 1px solid #ff8c21;
                            }
                          }
                        }
                      }
                    }

                    .group-multiple-price-content *:last-child {
                      border: unset;
                    }
                  }
                }
              }
            }
          }

          .group-product-option {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 24px;
            .group-collapse {
              border: unset;
              border-radius: 8px;

              .ant-collapse-item {
                border: unset;
                background: #f9f9f9;
                .ant-collapse-header {
                  background: #f9f9f9;
                  font-size: 20px;
                  font-weight: 700;
                  padding: 0px 0px 12px 0px;
                }
                .ant-collapse-content {
                  border: unset;
                  border-radius: 16px;
                  .ant-collapse-content-box {
                    padding: unset !important;

                    .group-product-option-content {
                      display: grid;
                      align-items: center;
                      justify-content: stretch;

                      .group-product-option-item {
                        height: fit-content;
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        padding: unset;
                        border-bottom: 1px solid #e6e6e6;
                        justify-content: space-between;

                        .option-name {
                          font-size: 16px;
                          font-weight: 500;
                          width: 250px;
                          overflow-wrap: break-word;
                        }

                        .option-level-value {
                          width: fit-content;
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          justify-content: flex-end;
                          margin: 8px;
                          gap: 10px;

                          .option-level-value-item {
                            height: unset;
                            border-radius: 7px;
                            background: #fff;
                            color: #000;
                            min-width: 82px;
                            font-size: 12px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-weight: 500;
                            border: unset;
                            width: fit-content;
                            word-break: break-all;

                            .ant-radio-button-checked {
                              background-color: #db4d29;
                              border-radius: 7.5px;
                              border: 1px solid #db4d29;

                              .ant-radio-button-input {
                                width: 0.5px;
                                height: 0.5px;
                                opacity: unset;
                              }

                              .ant-radio-button-input::after {
                                content: "";
                                width: 8px;
                                height: 8px;
                                background: #fff;
                                position: absolute;
                                top: 11px;
                                left: 5px;
                                border-radius: 100%;
                                transition: all 0.2s ease;
                              }
                            }

                            .ant-radio-button:last-child {
                              border: unset;
                            }
                          }

                          .ant-radio-button-wrapper-checked {
                            color: #fff;
                            font-size: 14px;
                          }

                          .option-level-value-item:not(:first-child)::before {
                            background-color: unset !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .group-product-topping {
            width: 100%;

            .group-collapse {
              border: unset;
              border-radius: 8px;

              .ant-collapse-item {
                border: unset;
                background: #f9f9f9;

                .ant-collapse-header {
                  background: #f9f9f9;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 28px;
                  padding: 0px 0px 12px 0px;
                }
                .ant-collapse-content {
                  border: unset;
                  border-radius: 16px;

                  .ant-collapse-content-box {
                    padding: unset !important;
                    border-radius: 16px;

                    .group-product-topping-content {
                      display: grid;
                      align-items: center;
                      border-radius: 16px;
                      padding: 16px;
                      .group-product-topping-item:hover {
                        border-radius: 16px;
                        background: #f9f9f9;
                      }
                      .group-product-topping-item {
                        min-height: 60px;
                        width: 100%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: unset;
                        padding: 12px;
                        gap: 10px;

                        .topping-name {
                          height: 100%;
                          display: grid;
                          align-items: center;

                          .name {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 24px;
                            word-break: break-word;
                          }

                          .topping-price-value {
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 20px;
                          }
                          .topping-original-price-value {
                            color: #959595;

                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            text-decoration-line: line-through;
                            padding-right: 12px;
                          }
                        }

                        .topping-change-quantity {
                          display: inline-flex;
                          align-items: center;
                          justify-content: end;

                          .topping-quantity-btn-increase,
                          .topping-quantity-btn-decrease {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            display: flex;
                            align-items: self-end;
                            justify-content: center;
                            font-weight: bold;
                            cursor: pointer;
                            align-items: center;
                          }

                          .topping-quantity-btn-decrease {
                            background: #f9f1df;
                            color: #51526c;
                          }

                          .topping-quantity-btn-increase {
                            background: #db4d29;
                            color: #fff;
                          }

                          .topping-quantity-value {
                            margin: 0 20px;
                            font-size: 18px;
                          }
                        }
                      }
                    }

                    .group-product-topping-content *:last-child {
                      border: unset;
                    }
                  }
                }
              }
            }
          }

          .input-note-product-detail {
            position: relative;
            .icon-note-product-detail {
              position: absolute;
              bottom: 25%;
              left: 2.5%;
            }
            .product-note {
              width: 100%;
              min-height: 56px;
              color: #282828;
              border-radius: 8px;
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
              padding: 20px 16px 20px 50px;
              border: 1px solid #e6e6e6;
            }
            .product-note:focus {
              border: 1px solid #e6e6e6;
            }
            .note-icon {
              position: relative;
              top: 48px;
              left: 10px;
              margin-left: 5px;
              z-index: 998;
            }
          }

          .btn-add-to-cart {
            width: 100%;
            min-height: 60px;
            background: #db4d29;
            margin-top: 24px;
            border-radius: 12px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            font-weight: 500;
            padding: 16px;

            .btn-add-to-cart-text {
              display: flex;
              align-items: center;

              .icon-check-out {
                margin-left: 10px;
              }
            }

            .btn-add-to-cart-price {
              display: flex;
              flex-direction: row;
              .btn-add-to-cart-origin-price-value {
                font-size: 16px;
                font-style: normal;
                line-height: 28px;
                text-decoration: line-through;
                font-weight: 400;
              }

              .btn-add-to-cart-price-value {
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                margin-left: 12px;
              }
            }

            .btn-add-to-cart-text {
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px;
              margin-right: 10px;
            }
          }

          .btn-add-to-cart:hover {
            cursor: pointer;
            font-weight: 500;
          }

          .margin-top {
            margin-top: 12px !important;
          }

          .group-product-combo {
            margin-top: 24px;
            width: 100%;

            .group-collapse {
              border: unset;
              border-radius: 8px;

              .product-name-banner {
                border: unset;

                &.unexpandable {
                  .ant-collapse-header {
                    cursor: auto;
                  }
                }

                .ant-collapse-header {
                  background: #f9f1df;
                  font-size: 16px;
                  font-weight: bold;
                  color: #000000;
                  border-radius: 8px;

                  .ant-collapse-header-text {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                  }
                }

                .ant-collapse-content {
                  border: unset;

                  .ant-collapse-content-box {
                    padding: 12px 0 0 0 !important;
                    .group-product-option,
                    .group-product-topping {
                      margin-top: unset;

                      .group-collapse {
                        .ant-collapse-item {
                          .ant-collapse-header {
                            background: #f9f9f9;
                            color: #959595;
                          }

                          .ant-collapse-content {
                            .ant-collapse-content-box {
                              padding-top: unset !important;
                            }
                          }
                        }
                      }
                    }

                    .group-product-topping {
                      margin-top: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .chatAboutProduct {
    width: 100% !important;
    padding: 20px 40px 40px 40px;
  }
  .product-detail-theme-pho-viet-section-reviews {
    border-radius: 12px;
    width: 100%;
    padding: 24px;
    background: #ffffff;

    .product-detail-reviews-title {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      gap: 12px;

      .product-detail-reviews-title-content {
        color: #282828;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        text-transform: uppercase;
      }
    }
    .product-detail-reviews-content {
      width: 100%;
      padding: 80px 0;
      background-color: #f9f9f9;
      border-radius: 12px;

      .product-detail-reviews-no-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
      }
      .product-detail-reviews-no-content-text {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: unset !important;
      }
    }
  }

  #relatedProductsDetail {
    .related-products-container {
      justify-content: center;
      align-items: center;
      display: flex;
      background-attachment: initial;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;

      .related-products {
        margin-bottom: 60px;
        font-style: normal;

        .top-content-product {
          align-items: center;
          justify-content: center;
          height: 104px;
          display: flex;

          .top-tile {
            font-weight: 700;
            font-size: 40px;
            line-height: 44px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #db4d29;
            label {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }

        .middle-content-product-detail {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          .product-row {
            display: flex;
            .product-box {
              width: 264px;
              height: 415px;
              border: 1px solid #e6e6e6;
              border-radius: 20px;
              color: #db4d29;
              margin-left: 22px;
              margin-right: 22px;
              background-color: #ffffff;
              .discount-box-top {
                margin-top: 12px;
                margin-left: 12px;
                height: 33px;

                .discount-box {
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  padding: 4px 8px;
                  gap: 10px;
                  width: 39px;
                  height: 24px;
                  background: #db4d29;
                  border-radius: 30px;

                  label {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    color: #ffffff;
                  }
                }
              }

              .thumnail {
                margin: 0px 32px 0px 32px;
                width: 200px;
                height: 196.65px;
              }

              .product-content {
                margin-left: 12px;
                margin-right: 12px;

                .rate {
                  display: flex;
                  align-items: center;
                  padding-bottom: 8px;
                }

                .name {
                  margin-top: 8px;
                  font-weight: 700;
                  font-size: 18px;
                  line-height: 22px;
                  align-items: center;
                  text-transform: capitalize;
                  color: #000000;
                }

                .description {
                  margin-top: 8px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 16px;
                  align-items: center;
                  color: #959595;
                }

                .bottom-content {
                  margin-top: 5px;
                  height: 36px;
                  width: 100%;
                  height: 36px;
                  display: flex;

                  .price-box-left {
                    width: 200px;
                    align-items: center;
                    display: flex;

                    .original-price-related {
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 16px;
                      margin-right: 12px;
                      text-decoration-line: line-through;
                      color: #959595;
                    }

                    .price {
                      font-weight: 700;
                      font-size: 15px;
                      line-height: 18px;
                      color: #db4d29;
                    }
                  }

                  .delete {
                    float: inline-end;
                  }

                  .icon {
                    cursor: pointer;
                  }
                }
              }
            }

            .product-main-theme2 {
              .discount-box {
                left: -5%;
              }
            }

            .product-main-theme2 {
              width: 273px;
              .discount-box {
                left: -5%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                min-width: 39px;
                height: 24px;
                white-space: nowrap;
                max-width: 100%;
                background: #ffa318;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 500px) {
  .middle-content-product-detail {
    .product-row {
      flex-wrap: wrap;
      row-gap: 30px;
      column-gap: 30px;
      justify-content: center;
    }
  }
}

@media (min-width: 500px) and (max-width: 885px) {
  .middle-content-product-detail {
    .product-row {
      .product-item-card {
        flex: 0 0 40%;
      }
    }
  }
}
.related-product-detail-title {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 21px;
  color: #282828;
}

#productDetailHeader {
  height: 322px;
  width: 100%;
  text-align: center;

  .product-detail-top-img {
    position: relative;
    height: 100%;
    width: 100%;
    background-attachment: initial;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;

    .title-product {
      position: absolute;
      font-size: 32px;
      font-weight: 700;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .span-title-product {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 90%;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 56px;
      }
    }
  }
}

@media (max-width: 1920px) {
  .product-detail-top-img {
    .title-product {
      z-index: 1;
    }

    .img-default {
      transform: translateX(-15%);
    }
  }
}

.detail-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-50 {
  max-width: 100%;
  min-width: 1178px;
}

.w-100 {
  width: 100%;
}

.display-none {
  display: none !important;
}

@media (max-width: 400px) {
  .product-detail-theme-pho-viet-section {
    .productDetailBodyAndChat {
      .product-detail-theme-pho-viet-section-group {
        .product-detail-theme-pho-viet-section-right {
          .product-detail-theme-pho-viet-section-right-content {
            .btn-add-to-cart {
              .btn-add-to-cart-price-value {
                &--down-fs {
                  font-size: 18px;
                }
              }
              .btn-add-to-cart-text {
                &--down-fs {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 370px) {
  .product-detail-theme-pho-viet-section {
    .productDetailBodyAndChat {
      .product-detail-theme-pho-viet-section-group {
        .product-detail-theme-pho-viet-section-right {
          .product-detail-theme-pho-viet-section-right-content {
            .btn-add-to-cart {
              .btn-add-to-cart-price-value {
                &--down-fs {
                  font-size: 16px !important;
                }
              }
              .btn-add-to-cart-text {
                &--down-fs {
                  font-size: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .product-detail-theme-pho-viet-section {
    width: 100vw;
    #productDetailBody {
      .product-detail-theme-pho-viet-section-group {
        display: grid;
        min-width: 100vw;
        max-width: 100vw;

        .product-detail-theme-pho-viet-section-left {
          padding: unset;
          height: max-content;
          width: 100vw;
          margin: unset;

          > .ant-image {
            border: 1px solid #e6e6e6;
            padding: 10px !important;
            width: calc(100vw - 32px);
            margin: auto;
          }

          .ant-image {
            display: flex;
            align-items: center;
            justify-content: center;

            .product-image {
              width: 100%;
            }
          }

          .discount-product-detail {
            top: 10px;
          }

          .swiper {
            width: calc(100vw - 32px);
          }

          .image-sub-group {
            margin-top: 22px;
            display: none; // hidden by new design.
            align-items: center;
            float: left;
            padding: 0 16px;
            .ant-image {
              height: 108px;
              .image-sub {
                width: 108px;
                height: 108px;
                border-radius: 12px;
              }
            }
          }
        }

        .product-detail-theme-pho-viet-section-right {
          width: 100vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          .product-detail-theme-pho-viet-section-right-content {
            width: calc(100vw - 32px);
            .product-name {
              font-size: 24px;
            }

            .group-star-rate {
              .total-review {
                font-size: 14px;
              }
            }

            .product-price {
              .product-price-left {
                display: grid;

                .product-price-discount {
                  margin-left: unset;
                }
              }

              .product-price-right {
                .product-price-btn-increase,
                .product-price-btn-decrease {
                  width: 32px;
                  height: 32px;
                }
              }
            }

            .btn-add-to-cart {
              font-size: 18px;
              .btn-add-to-cart-price-value {
                margin-left: 16px;
              }
              .btn-add-to-cart-text {
                margin-right: 16px;
                .icon-check-out {
                  margin-left: 4px;
                }
              }
            }
          }

          .group-product-option {
            .group-collapse {
              .ant-collapse-item {
                .ant-collapse-content {
                  .ant-collapse-content-box {
                    .group-product-option-content {
                      .group-product-option-item {
                        .option-level-value {
                          width: fit-content;
                          overflow-x: auto;
                          display: flex;
                          align-items: center;
                          .option-level-value-item {
                            min-width: auto !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #productDetailBody.detail-center {
      justify-content: unset;
    }

    .detail-center {
      display: flex;
      justify-content: center;
      width: 100vw;
      align-items: center;
    }

    .product-detail-theme-pho-viet-section-reviews {
      height: 327px;
      margin: unset !important;
      min-width: calc(100vw - 32px);
      max-width: calc(100vw - 32px);
    }

    .w-50 {
      max-width: calc(100vw - 32px);
      min-width: calc(100vw - 32px);
    }

    #relatedProductsDetail {
      .related-products-container {
        .related-products {
          width: 100%;
          .middle-content-product-detail {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .product-row {
              display: flex;
              width: 100%;
              .product-box {
                width: 264px;
                height: 415px;
                border: 1px solid #e6e6e6;
                border-radius: 20px;
                color: #db4d29;
                margin-left: 22px;
                margin-right: 22px;
                background-color: #ffffff;
                .discount-box-top {
                  margin-top: 12px;
                  margin-left: 12px;
                  height: 33px;

                  .discount-box {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 4px 8px;
                    gap: 10px;
                    width: 39px;
                    height: 24px;
                    background: #db4d29;
                    border-radius: 30px;

                    label {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 16px;
                      color: #ffffff;
                    }
                  }
                }

                .thumnail {
                  margin: 0px 32px 0px 32px;
                  width: 200px;
                  height: 196.65px;
                }

                .product-content {
                  margin-left: 12px;
                  margin-right: 12px;

                  .rate {
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;
                  }

                  .name {
                    margin-top: 8px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    align-items: center;
                    text-transform: capitalize;
                    color: #000000;
                  }

                  .description {
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    align-items: center;
                    color: #959595;
                  }

                  .bottom-content {
                    margin-top: 5px;
                    height: 36px;
                    width: 100%;
                    height: 36px;
                    display: flex;

                    .price-box-left {
                      width: 200px;
                      align-items: center;
                      display: flex;

                      .original-price-related {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        margin-right: 12px;
                        text-decoration-line: line-through;
                        color: #959595;
                      }

                      .price {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 18px;
                        color: #db4d29;
                      }
                    }

                    .delete {
                      float: inline-end;
                    }

                    .icon {
                      cursor: pointer;
                    }
                  }
                }
              }

              .product-main-theme2 {
                margin-top: unset !important;
                width: 273px !important;
                margin-right: 16px;
                height: 445px !important;

                .price-box {
                  width: 100%;
                  .price-box-left {
                    flex-direction: unset !important;
                    align-items: center;
                    .product-price {
                      margin-top: unset;
                      margin-right: 12px;
                    }

                    .product-price-with-discount {
                      margin-top: unset;
                    }
                  }
                }

                .product-image {
                  width: 100%;
                  height: 249px;
                }

                .discount-box {
                  top: 4%;
                  left: 3%;
                }

                .product-name {
                  width: 100%;
                  .item {
                    font-size: 16px;
                  }
                }

                .product-rating {
                  margin-top: 8px;
                }

                .product-description {
                  font-size: 14px;
                  width: 100%;
                  .ant-typography {
                    color: #282828;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px; /* 142.857% */
                    .ant-typography-expand {
                      font-size: 14px;
                    }
                  }
                }
              }

              .swiper-related-product {
                .swiper-wrapper {
                  height: 490px;
                  .swiper-slide-related-product {
                    width: fit-content;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-description
    .ant-typography {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    .ant-typography-expand {
      font-size: 14px;
    }
  }
}

@media (max-width: 420px) {
  .product-detail-theme-pho-viet-section-right {
    .product-detail-theme-pho-viet-section-right-content {
      .btn-add-to-cart {
        line-height: 20px;
        .btn-add-to-cart-price {
          flex-direction: column !important;
          .btn-add-to-cart-origin-price-value {
            font-size: 14px;
          }
          .btn-add-to-cart-price-value {
            font-size: 16px;
            margin-left: 16px !important;
          }
        }

        .btn-add-to-cart-text {
          margin-right: 10px !important;
          .icon-check-out {
            margin-left: 5px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .product-detail-theme-pho-viet-section {
    width: 100vw;
    #productDetailBody {
      .product-detail-theme-pho-viet-section-group {
        display: grid;
        min-width: 100vw;
        max-width: 100vw;

        .product-detail-theme-pho-viet-section-left {
          padding: unset;
          height: max-content;
          margin: unset;

          > .ant-image {
            border: 1px solid #e6e6e6;
            padding: 10px !important;
            width: calc(100vw - 32px);
            margin: auto;
          }

          .ant-image {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 500px;
            .product-image {
              width: 100%;
            }
          }

          .discount-product-detail {
            top: 10px;
          }

          .swiper {
            width: calc(100vw - 32px);
          }

          .image-sub-group {
            margin-top: 22px;
            display: none; // hidden by new design.
            align-items: center;
            float: left;
            padding: 0 16px;
            .ant-image {
              height: 108px;
              .image-sub {
                width: 108px;
                height: 108px;
                border-radius: 12px;
              }
            }
          }
        }

        .product-detail-theme-pho-viet-section-right {
          display: flex;
          align-items: center;
          justify-content: center;

          .product-detail-theme-pho-viet-section-right-content {
            width: calc(100vw - 32px);
            .product-name {
              font-size: 24px;
            }

            .group-star-rate {
              .total-review {
                font-size: 14px;
              }
            }

            .product-price {
              .product-price-left {
                display: grid;

                .product-price-discount {
                  margin-left: unset;
                }
              }

              .product-price-right {
                .product-price-btn-increase,
                .product-price-btn-decrease {
                  width: 32px;
                  height: 32px;
                }
              }
            }
          }

          .group-product-option {
            .group-collapse {
              .ant-collapse-item {
                .ant-collapse-content {
                  .ant-collapse-content-box {
                    .group-product-option-content {
                      .group-product-option-item {
                        .option-level-value {
                          width: fit-content;
                          overflow-x: auto;
                          display: flex;
                          align-items: center;
                          .option-level-value-item {
                            min-width: auto !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    #productDetailBody.detail-center {
      justify-content: unset;
    }

    .detail-center {
      display: flex;
      justify-content: center;
      width: 100vw;
      align-items: center;
    }

    .product-detail-theme-pho-viet-section-reviews {
      height: 327px;
      margin: unset !important;
      min-width: calc(100vw - 32px);
      max-width: calc(100vw - 32px);
    }

    .w-50 {
      max-width: calc(100vw - 32px);
      min-width: calc(100vw - 32px);
    }

    #relatedProductsDetail {
      .related-products-container {
        .related-products {
          width: 100%;
          .middle-content-product-detail {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .product-row {
              display: flex;
              width: 100%;
              overflow: hidden;
              .product-box {
                width: 264px;
                height: 415px;
                border: 1px solid #e6e6e6;
                border-radius: 20px;
                color: #db4d29;
                margin-left: 22px;
                margin-right: 22px;
                background-color: #ffffff;
                .discount-box-top {
                  margin-top: 12px;
                  margin-left: 12px;
                  height: 33px;

                  .discount-box {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 4px 8px;
                    gap: 10px;
                    width: 39px;
                    height: 24px;
                    background: #db4d29;
                    border-radius: 30px;

                    label {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 16px;
                      color: #ffffff;
                    }
                  }
                }

                .thumnail {
                  margin: 0px 32px 0px 32px;
                  width: 200px;
                  height: 196.65px;
                }

                .product-content {
                  margin-left: 12px;
                  margin-right: 12px;

                  .rate {
                    display: flex;
                    align-items: center;
                    padding-bottom: 8px;
                  }

                  .name {
                    margin-top: 8px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    align-items: center;
                    text-transform: capitalize;
                    color: #000000;
                  }

                  .description {
                    margin-top: 8px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    align-items: center;
                    color: #959595;
                  }

                  .bottom-content {
                    margin-top: 5px;
                    height: 36px;
                    width: 100%;
                    height: 36px;
                    display: flex;

                    .price-box-left {
                      width: 200px;
                      align-items: center;
                      display: flex;

                      .original-price-related {
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 16px;
                        margin-right: 12px;
                        text-decoration-line: line-through;
                        color: #959595;
                      }

                      .price {
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 18px;
                        color: #db4d29;
                      }
                    }

                    .delete {
                      float: inline-end;
                    }

                    .icon {
                      cursor: pointer;
                    }
                  }
                }
              }

              .product-main-theme2 {
                margin-top: unset !important;
                width: 273px !important;
                margin-right: 12px;

                .price-box {
                  width: 100%;
                  .price-box-left {
                    flex-direction: unset !important;
                    align-items: center;
                    .product-price {
                      margin-top: unset;
                      margin-right: 12px;
                    }

                    .product-price-with-discount {
                      margin-top: unset;
                    }
                  }
                }

                .product-img {
                  width: 100%;
                  height: 100%;
                }

                .discount-box {
                  top: 4%;
                  left: 3%;
                }

                .product-name {
                  width: 100%;
                  .item {
                    font-size: 16px;
                  }
                }

                .product-rating {
                  margin-top: 8px;
                }

                .product-description {
                  font-size: 14px;
                  width: 100%;
                }
              }

              .swiper-related-product {
                .swiper-wrapper {
                  .swiper-slide-related-product {
                    width: fit-content;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .product-detail-theme-pho-viet-section {
    .productDetailBodyAndChat {
      .detail-center {
        .product-detail-theme-pho-viet-section-group {
          min-width: 90% !important;
          .product-detail-theme-pho-viet-section-left {
            margin-right: 50px;
            width: 50%;
          }
        }
      }
      .chatAboutProduct {
        .product-detail-theme-pho-viet-section-reviews {
          min-width: 90%;
        }
      }
    }
    #relatedProductsDetail {
      min-width: 100% !important;
    }
  }
}

@media (min-width: 950px) and (max-width: 1250px) {
  .product-detail-theme-pho-viet-section {
    .relatedProductsDetail {
      .related-products-container {
        .related-products {
          .middle-content-product-detail {
            .product-row {
              display: flex;
              justify-content: flex-start !important;
              width: 930px !important;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 1024px) {
  .product-detail-theme-pho-viet-section {
    .productDetailBodyAndChat {
      .detail-center {
        .product-detail-theme-pho-viet-section-group {
          .product-detail-theme-pho-viet-section-left {
            padding-left: 20px !important;
          }
          .product-detail-theme-pho-viet-section-right {
            margin-left: auto;
            margin-right: auto;
            width: 96vw !important;
          }
        }
      }
    }
  }
}

// new design

/* Hide scrollbar for Chrome, Safari and Opera */

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.group-multiple-price {
  overflow-x: scroll;
}
.radio-group--type-button {
  flex-wrap: nowrap !important;
  overflow-x: scroll;
}
.product-detail-theme-pho-viet-section {
#relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    > .swiper-horizontal
    > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    display: none;
  }
}
.line-in-small-screen {
  display: none;
}
.displayed-when-medium-screen,
.displayed-when-small-screen {
  display: none;
}
.product-detail-theme-pho-viet-section {
  .back-to-history-icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    .flash-sale-banner {
      margin-top: 0px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-name {
    display: none !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    > .group-collapse
    > .product-name-banner
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 4px 24px 12px 24px !important;
    background: #f9f9f9;
    border-radius: 8px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row {
    .swiper-related-product {
      width: 100%;
      .swiper-wrapper {
        .swiper-slide {
          > .product-main-theme2 {
            width: 100%;
            .product-description {
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    .product-main-content-theme2 {
      margin-bottom: 0px;
    }
  }
  .product-detail-theme-pho-viet-section #relatedProductsDetail .related-products-container .related-products {
    width: 100%;
    .product-row {
      width: 100%;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .topping-and-option {
    margin-top: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-description {
    margin-top: 0px;
    .ant-typography {
      margin-bottom: 0px !important;
    }
  }
  .product-detail-theme-pho-viet-section .mt-24 {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .btn-add-to-cart {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-multiple-price {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .top-content-product
    .top-tile {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .topping-and-option {
    gap: 0px;
    padding-bottom: 12px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option {
    gap: 0px;
  }
  .topping-and-option-in-combo {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content-active {
    margin-bottom: 24px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content {
    margin-bottom: 24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse {
    background: #f9f9f9 !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .margin-top {
    margin-top: 0px !important;
  }
  .combo-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    > .group-collapse
    > .product-name-banner
    > .ant-collapse-content {
    margin-top: 12px;
  }
  .topping-and-option {
    .ant-collapse:last-child {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-bottom: 12px !important;
        }
      }
    }
  }
  .topping-and-option-in-combo {
    .ant-collapse:last-child {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-bottom: 12px !important;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 1023px) {
  #productDetailHeader {
    position: relative;
    .back-to-history-icon {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    .flash-sale-banner {
      margin-left: -24px;
      width: calc(100% + 48px);
      border-radius: 0px;
      margin-top: 0px;
    }
  }
  .product-detail-theme-pho-viet-section #productDetailBody .product-detail-theme-pho-viet-section-group {
    min-width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .main-image-box {
    width: calc(100% + 24px);
    margin-left: -24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .discount-product-detail {
    top: -14px;
    left: -14px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-name {
    display: none !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    > .group-collapse
    > .product-name-banner
    > .ant-collapse-content
    > .ant-collapse-content-box {
    padding: 4px 24px 12px 24px !important;
    background: #f9f9f9;
    border-radius: 8px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    .product-main-content-theme2 {
      margin-bottom: 0px;
    }
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2
    .price-box
    .price-box-left {
    flex-direction: column !important;
  }
  .product-detail-theme-pho-viet-section .chatAboutProduct {
    border-radius: 0px;
    padding: 0px;
  }
  .product-detail-theme-pho-viet-section #productDetailBody {
    padding: 0px;
  }
.product-detail-theme-pho-viet-section
    .productDetailBodyAndChat
    .detail-center
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right {
    width: 100% !important;
    .star-rating {
      margin-top: 24px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    width: 100%;
  }
  .product-detail-theme-pho-viet-section .product-detail-theme-pho-viet-section-reviews {
    padding-top: 36px;
    border-radius: 0px;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
  .relatedProductsDetail {
    margin-top: 60px;
  }
  #productDetailHeader .product-detail-top-img .title-product {
    .span-title-product {
      text-align: center;
    }
  }
  #productDetailHeader .product-detail-top-img .title-product .span-title-product {
    font-size: 32px;
    line-height: 44px;
  }
.product-detail-theme-pho-viet-section
    .productDetailBodyAndChat
    .detail-center
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left {
    padding: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left {
    width: 100%;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .ant-image {
    width: calc(100% + 48px);
    max-width: calc(100% + 48px);
    margin-left: -24px;
    margin-top: -24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .ant-image
    .product-image {
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    .m-content {
      padding: 0px 12px;
    }
    .price-box {
      width: 100%;
      margin: 0px;
      padding: 8px 12px 12px 12px;
      height: auto;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option {
    margin-top: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-description {
    margin-top: -12px !important;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    margin-bottom: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .topping-and-option {
    margin-top: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-description {
    margin-top: 0px;
    .ant-typography {
      margin-bottom: 0px !important;
    }
  }
  .product-detail-theme-pho-viet-section .mt-24 {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .btn-add-to-cart {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-multiple-price {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    display: flex;
    gap: 24px;
    flex-direction: column;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .top-content-product
    .top-tile {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .topping-and-option {
    gap: 0px;
    padding-bottom: 12px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option {
    gap: 0px;
  }
  .topping-and-option-in-combo {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content-active {
    margin-bottom: 24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content {
    margin-bottom: 24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse {
    background: #f9f9f9 !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .margin-top {
    margin-top: 0px !important;
  }
  .combo-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    > .group-collapse
    > .product-name-banner
    > .ant-collapse-content {
    margin-top: 12px;
  }
  .topping-and-option {
    .ant-collapse:last-child {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-bottom: 12px !important;
        }
      }
    }
  }
  .topping-and-option-in-combo {
    .ant-collapse:last-child {
      .ant-collapse-item {
        .ant-collapse-content {
          margin-bottom: 12px !important;
        }
      }
    }
  }
  .displayed-when-medium-screen {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  $background-color-of-break-line: #f4f4f4;
  //Todo use
  // .line-in-small-screen {
  //   display: flex;
  //   margin-left: -16px;
  //   width: calc(100% + 32px);
  //   height: 16px;
  //   background: $background-color-of-break-line;
  // }
  .displayed-when-small-screen {
    display: block;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .combo-content {
    margin-top: 24px;
    .group-product-combo {
      margin-top: 0px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content {
    .flash-sale-banner {
      margin-left: -16px;
      width: calc(100% + 32px);
      border-radius: 0px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .product-detail-header {
    display: none;
  }
  .product-detail-theme-pho-viet-section #productDetailBody {
    padding: 0px;
  }
  .product-detail-theme-pho-viet-section #productDetailBody .product-detail-theme-pho-viet-section-group {
    border-radius: 0px;
    padding: 0px;
  }
  .product-detail-theme-pho-viet-section #productDetailBody .product-detail-theme-pho-viet-section-group {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 16px 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .ant-image
    .product-image {
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-description {
    margin-top: 12px;
    margin-bottom: 12px;
    .ant-typography {
      margin-bottom: 0px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price {
    margin-top: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price
    .product-price-left {
    display: flex;
    flex-direction: column-reverse;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price
    .product-price-left
    .product-price-discount {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price
    .product-price-left
    .product-price-original {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price {
    align-items: flex-start;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-multiple-price
    .size-header {
    font-size: 16px;
    line-height: 24px;
  }
  .radio-group--type-button .ant-radio-wrapper-checked .radio-item .radio-content .name,
  .radio-group--type-button .ant-radio-wrapper-checked .radio-item .radio-content .value {
    font-size: 14px;
    line-height: 16px;
  }
  .radio-group--type-button .radio-item .radio-content .name,
  .radio-group--type-button .radio-item .radio-content .value {
    font-size: 14px;
    line-height: 16px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .topping-and-option {
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 0px;
    gap: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box
    .group-product-topping-content {
    padding: 0px;
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box
    .group-product-topping-content
    .group-product-topping-item {
    padding-left: 0px;
    padding-right: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content {
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-header {
    padding: 12px 16px;
    border-top: 16px solid $background-color-of-break-line;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content {
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option {
    gap: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-topping
    .group-collapse
    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-content-box
    .group-product-topping-content
    .group-product-topping-item {
    padding: 16px;
    &:hover {
      border-radius: 0px;
    }
  }
  .group-product-option-content {
    .radio-group--type-radio {
      padding: 0px;
      .radio-item:hover {
        border-radius: 0px;
      }
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-option
    .group-collapse
    .ant-collapse-item
    .ant-collapse-header {
    padding: 12px 16px;
    border-top: 16px solid $background-color-of-break-line;
  }
  .product-detail-theme-pho-viet-section .product-note {
    border-top: 16px solid $background-color-of-break-line;
    margin-left: -16px;
    width: calc(100% + 32px);
    padding: 24px 16px;
    .note-text-area {
      margin-top: 0px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .product-price
    .product-price-left
    .product-price-original-box {
    display: flex;
    align-items: center;
    .promotion-tag-for-small-screen {
      display: flex;
      padding: 4px 12px;
      border-radius: 20px;
      color: #ffffff;
      background: #ffa318;
      align-items: flex-start;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-decoration: none;
      margin-right: 8px;
    }
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .btn-add-to-cart {
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    border-radius: 0px;
    .btn-add-to-cart-price {
      flex-direction: column;
      .btn-add-to-cart-price-value {
        margin-left: 0px !important;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
      }
      .btn-add-to-cart-origin-price-value {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .btn-add-to-cart-text {
      margin-right: 0px !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .product-detail-theme-pho-viet-section .chatAboutProduct {
    border-top: 16px solid $background-color-of-break-line;
    padding: 0px;
    height: fit-content;
    .product-detail-theme-pho-viet-section-reviews {
      border-radius: 0px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      padding: 16px;
      height: auto;
    }
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .top-content-product
    .top-tile {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding: 12px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .top-content-product {
    height: fit-content;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-left
    .discount-product-detail {
    display: none;
  }
  #back2Top {
    bottom: 90px;
    right: 10px;
    z-index: 3;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    .group-collapse
    .product-name-banner
    .ant-collapse-header {
    border-radius: 0px;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    .group-collapse
    .product-name-banner
    .ant-collapse-content
    .ant-collapse-content-box {
    padding: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #productDetailBody
    .product-detail-theme-pho-viet-section-group
    .product-detail-theme-pho-viet-section-right
    .product-detail-theme-pho-viet-section-right-content
    .group-product-combo
    .group-collapse
    .product-name-banner
    .ant-collapse-content
    .ant-collapse-content-box
    .group-product-topping {
    margin-top: 0px;
  }

  //Related Products
  .product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .swiper-related-product
    .swiper-wrapper
    .swiper-slide-related-product {
    width: calc((100% - 24px) * 0.5);
    height: fit-content;
    margin-right: 0px !important;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    margin-right: 0px !important;
    margin-bottom: 0px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    width: 100% !important;
    height: fit-content !important;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .swiper-related-product
    .swiper-wrapper {
    gap: 20px;
    height: fit-content;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2
    .product-description {
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    align-self: stretch;
    overflow: hidden;
    color: var(--color-default-button-topping-option-selling-price-time-quantity-price, #959595);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: fit-content;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2
    .price-box
    .price-box-left {
    flex-direction: column !important;
    align-items: flex-start;

    .product-price-with-discount {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
    }
    .product-price {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
    }
  }
  .product-detail-theme-pho-viet-section #relatedProductsDetail .related-products-container .related-products {
    margin-bottom: 24px;
  }
.product-detail-theme-pho-viet-section
    #relatedProductsDetail
    .related-products-container
    .related-products
    .middle-content-product-detail
    .product-row
    .product-main-theme2 {
    .m-content {
      padding: 0px 8px;
      margin: 0px;
    }
    .price-box {
      width: 100%;
      margin: 0px;
      padding: 4px 8px 8px 8px;
      height: auto;
    }
    .product-rating {
      margin-top: 0px;
    }
  }
  .product-main-theme2 .ant-card-body .product-main-content-theme2 {
    padding-bottom: 0px;
  }
}
